/*
 * Copyright 2014-2018 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default (fullName, targetLength) => {
  if (!fullName || fullName.length < targetLength) {
    return fullName;
  }

  const tokens = fullName.split('.');
  let shortened = tokens.pop();
  while (tokens.length > 0) {
    const next = tokens.pop();
    if (next.length + 1 + shortened.length < targetLength) {
      shortened = next + '.' + shortened;
    } else {
      shortened = next[0] + '.' + shortened;
    }
  }
  return shortened;
};
