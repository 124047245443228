/*
 * Copyright 2014-2019 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const compareBy = mapper => (a, b) => {
  const valA = mapper(a);
  const valB = mapper(b);
  return valA > valB ? 1 : valA < valB ? -1 : 0;
};

export const anyValueMatches = (obj, predicate) => {
  if (Array.isArray(obj)) {
    return obj.some(e => anyValueMatches(e, predicate));
  } else if (obj !== null && typeof obj === 'object') {
    return anyValueMatches(Object.values(obj), predicate);
  }
  return predicate(obj);
};
